import React from "react";
import styled from '@emotion/styled';
import mediaqueries from "@styles/media";

const Logo = ({ fill = 'white' }) => {
  return (
    <LogoContainer>
      <svg
        width="165"
        height="49"
        viewBox="0 0 165 49"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
          <path d="M36.2073 36.5208H9.13696V39.5833H36.2073V36.5208Z" fill={fill} />
          <path d="M9.13696 21.2083V34.4791L16.8713 29.9269V25.7605L9.13696 21.2083Z" fill={fill} />
          <path d="M36.2073 25.2917V11L28.4729 15.8936V20.3146L36.2073 25.2917Z" fill={fill} />
          <path
            d="M36.0133 35.1959L35.9806 26.7455L9 11.2172V19.5868L36.0133 35.1959Z"
            fill={fill}
          />
          <path
            d="M47.68 18.36V16.2H58.6V17.64L50.32 30.792V30.84H58.6V33H47.2V31.56L55.48 18.408V18.36H47.68ZM63.8071 28.08V31.032H68.6311V28.08H63.8071ZM64.2631 33.12L61.4791 31.512V27.6L64.2631 25.992H68.6311V23.088H62.6791V21H68.1751L70.9591 22.608V33.12H70.3351L68.6311 32.136L66.9271 33.12H64.2631ZM77.1308 23.088V30.912H83.8028V33H77.5868L74.8028 31.392V22.608L77.5868 21H83.8028V23.088H77.1308ZM89.4824 33H87.1544V15.48H89.4824V21.864L91.1864 20.88H94.8104L97.5944 22.488V33H95.2664V22.968H89.4824V33Z"
            fill={fill}
          />
          <path
            d="M106.68 18.36V16.2H117.6V17.64L109.32 30.792V30.84H117.6V33H106.2V31.56L114.48 18.408V18.36H106.68ZM123.287 33H120.959V15.48H123.287V21.864L124.991 20.88H128.615L131.399 22.488V33H129.071V22.968H123.287V33ZM137.081 28.08V31.032H141.905V28.08H137.081ZM137.537 33.12L134.753 31.512V27.6L137.537 25.992H141.905V23.088H135.953V21H141.449L144.233 22.608V33.12H143.609L141.905 32.136L140.201 33.12H137.537ZM158.276 31.512L155.492 33.12H150.86L148.076 31.512V22.488L150.86 20.88H155.492L158.276 22.488V31.512ZM150.404 22.968V31.032H155.948V22.968H150.404Z"
            fill="#7A8085"
          />
        </g>
        <defs>
          <clipPath id="clip0">
            <rect width="165" height="49" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <svg
        width="18"
        height="23"
        viewBox="0 0 18 23"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        className="Logo__Mobile">
        <path d="M17.5907 20.2954H0V23H17.5907V20.2954Z" fill={fill} />
        <path d="M0 7.96484V18.9537L5.38796 15.1843V11.7343L0 7.96484Z" fill={fill} />
        <path d="M17.5689 10.9463V0L12.1597 3.74815V7.13426L17.5689 10.9463Z" fill={fill} />
        <path d="M17.5907 18.975L17.5694 12.288L0 0V6.62315L17.5907 18.975Z" fill={fill} />
      </svg>
    </LogoContainer>
  );
};

export default Logo;

const LogoContainer = styled.div`
  .Logo__Mobile {
    display: none;
  }

  ${mediaqueries.tablet`
    .Logo__Desktop {
      display: none;
    }
    
    .Logo__Mobile{
      display: block;
    }
  `}
`;
